import React, { Fragment } from "react"
import classNames from "classnames"

import { printDate } from "../../services/general"
import styles from "../Enrollment/utils/enrollment.module.scss"

const PackageFormAddon = ({ selectedPackage, values }) => {
  return (
    <Fragment>
      <div className="table-container mb-3">
        <table class="table is-fullwidth is-size-5">
          <tbody>
            <div className="table-container mb-3">
              <table class="table is-fullwidth is-size-5">
                <tbody>
                  {selectedPackage === "Hi-Precision Diagnostics Centre" && (
                    <Fragment>
                      <tr>
                        <td className="has-text-weight-bold">Birthday</td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {printDate(values?.birthday)}
                        </td>
                      </tr>
                      <tr>
                        <td className="has-text-weight-bold">Sex</td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {values?.sex}
                        </td>
                      </tr>
                      <tr>
                        <td className="has-text-weight-bold">
                          Preferred Branch
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {values?.branch?.value}
                        </td>
                      </tr>
                    </Fragment>
                  )}
                  <tr>
                    <td className="has-text-weight-bold">
                      Preferred Appointment Date
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {printDate(values?.preferredAppointmentDate)}
                    </td>
                  </tr>
                  <tr>
                    <td className="has-text-weight-bold">
                      Preferred Appointment Time
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {values?.preferredAppointmentTime?.value}
                    </td>
                  </tr>
                  {selectedPackage === "Hi-Precision Diagnostics Centre" && (
                    <Fragment>
                      <tr>
                        <td className="has-text-weight-bold">
                          Availed Home Service
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {values?.availHomePackage?.length > 0 ? "YES" : "NO"}
                        </td>
                      </tr>
                      {values?.availHomePackage?.length > 0 && (
                        <Fragment>
                          <tr>
                            <td className="has-text-weight-bold">
                              Street Address
                            </td>
                            <td
                              className={classNames(
                                styles["summary__tableData"]
                              )}
                            >
                              {values?.homeAddress?.streetAddress}
                            </td>
                          </tr>
                          <tr>
                            <td className="has-text-weight-bold">Province</td>
                            <td
                              className={classNames(
                                styles["summary__tableData"]
                              )}
                            >
                              {values?.homeAddress?.province?.value}
                            </td>
                          </tr>
                          <tr>
                            <td className="has-text-weight-bold">City</td>
                            <td
                              className={classNames(
                                styles["summary__tableData"]
                              )}
                            >
                              {values?.homeAddress?.city?.value}
                            </td>
                          </tr>
                          <tr>
                            <td className="has-text-weight-bold">Barangay</td>
                            <td
                              className={classNames(
                                styles["summary__tableData"]
                              )}
                            >
                              {values?.homeAddress?.barangay?.value}
                            </td>
                          </tr>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default PackageFormAddon
