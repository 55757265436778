import moment from "moment"

export const isObjectEmpty = object => {
  for (var i in object) return false
  return true
}

export const isBrowser = () => typeof window !== "undefined"

export const capitalize = string => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const generateRandomString = ({ length, inclusions }) => {
  let result = ""
  let upperCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let lowerCharacters = "abcdefghijklmnopqrstuvwxyz"
  let numbers = "0123456789"

  let characters = `${inclusions.includes("upper") ? upperCharacters : ""}${
    inclusions.includes("lower") ? lowerCharacters : ""
  }${inclusions.includes("numbers") ? numbers : ""}`

  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const isFutureDate = dateInQuestion => {
  if (dateInQuestion) {
    let { month, date, year } = dateInQuestion
    let dateToday = new Date()

    if (date)
      return dateToday < moment(`${month.value} ${date.value} ${year}`).toDate()
  }
  return false
}

export const printDate = dateInQuestion => {
  if (dateInQuestion) {
    let { month, date, year } = dateInQuestion
    if (month?.value && date?.value && year)
      return `${month?.value} ${date?.value} ${year}`
  }
  return ""
}
