const checkFileUploads = (docTypes, state) => {
  for (let index in docTypes) {
    let documentCount = state?.documents.filter(document =>
      document.name.startsWith(docTypes[index])
    ).length
    if (documentCount === 0) return true
  }
  return false
}

export const disableButton = (module, state) => {
  switch (module) {
    // case "patient":
    //   return checkFileUploads(["gid", "lab"], state)
    default:
      return false
  }
}

export const getInitialFormValues = ({
  state,
  module,
  enrollmentIntialState,
}) => {
  switch (module) {
    case "patient":
      return Object.keys(state.patientEnrollment).length === 0
        ? {
            ...enrollmentIntialState,
            preferredLaboratory: {
              value: "Philippine Heart Center (PHC)",
              label: "Philippine Heart Center (PHC)",
            },
            packageAvailed: {
              testPackage: "HbA1c, Lipid Profile, SGPT, Creatine",
              amount: "1,250.00",
              name: "Philippine Heart Center (PHC)",
              logo: "phcLogo",
              tests: ["HbA1c", "Lipid Profile", "SGPT", "Creatinine"],
              rawText:
                "Philippine Heart Center (PHC)\nAddress: East Avenue, Diliman, Quezon City, Metro Manila\nOperating hours: Mondays to Sundays | 24-hour operations",
              description: `\n<p class="mb-1"><b>Philippine Heart Center</b></p>\n<p class="is-size-6 mb-0"><b>Address:</b> East Avenue, Diliman, Quezon City, Metro Manila</p>\n<p class=\"is-size-6 mb-0\"><b>Operating hours:</b> Mondays to Sundays | 24-hour operations</p>`,
            },
            availHomePackage: [],
            clinicHospital: "Philippine Heart Center (PHC)",
            prescribedTests: "HbA1c, Lipid Profile, SGPT, and Creatinine",
            birthday: {
              month: { value: "" },
              date: { value: "" },
              year: "",
            },
            sex: "",
            branch: { label: "", value: "" },
            preferredAppointmentDate: {
              month: { value: "" },
              date: { value: "" },
              year: "",
            },
            preferredAppointmentTime: { label: "", value: "" },
            homeAddress: {
              streetAddress: "",
              city: { value: "" },
              province: { value: "", label: "" },
              barangay: { value: "" },
            },
          }
        : state.patientEnrollment
    case "doctor":
      return Object.keys(state.doctorEnrollment).length === 0
        ? {
            ...enrollmentIntialState,
            hospitalClinic: "Philippine Heart Center (PHC)",
            // otherHospital: "",
            department: {
              value: "Adult Cardiology",
              label: "Adult Cardiology",
            },
            otherDepartment: "",
            wouldLikeToReceiveUpdates: [],
            doctorEmail: "",
            doctorMobileNumber: "",
            secretaryName: "",
            secretaryrEmail: "",
            secretaryrMobileNumber: "",
            reachedThrough: "",
            reachedVia: [],
            specialization: { value: "Cardiology", label: "Cardiology" },
            isAffiliatedWithAGovernment: "Yes",
          }
        : state.doctorEnrollment
    default:
      return {}
  }
}
