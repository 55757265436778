import React, { useContext, useState, useEffect, Fragment } from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import Section from "elements/Section"
import Message from "elements/Message"
import PackageFormAddon from "./PackageFormAddon"
import EditDetailsButton from "elements/EditDetailsButton"

import { AppContext } from "../../context/AppContext"
import styles from "../Elements/utils/elements.module.scss"
import { packages } from "../DiagnosticSubsidy/utils/packages"
import { useHookImages } from "../DiagnosticSubsidy/hooks/useHookImages"

const PatientEnrollmentSummaryAddon = () => {
  const [packagesIncluded, setPackagesIncluded] = useState([])
  const [laboratory, setLaboratory] = useState("")

  const { state, dispatch } = useContext(AppContext)
  const data = useHookImages()

  const PATIENT_ENROLLMENT_STATE = state?.patientEnrollment

  const setPackage = () => {
    let selectedHospital = PATIENT_ENROLLMENT_STATE?.clinicHospital

    if (selectedHospital)
      setPackagesIncluded(
        packages?.find(
          packageIncluded => packageIncluded?.hospital === selectedHospital
        )
      )
  }

  useEffect(() => {
    if (state?.patientEnrollment?.preferredLaboratory?.value) {
      setLaboratory({
        ...state?.patientEnrollment?.packageAvailed,
      })
    }
    setPackage()
  }, [
    state?.patientEnrollment?.preferredLaboratory?.value,
    state?.patientEnrollment?.packageAvailed,
  ])

  return (
    <div className="mt-3">
      <Section
        title="Diagnostic Test Package"
        isRequired
        addOns={{
          right: <EditDetailsButton route={`/patient/package`} />,
        }}
      >
        {state?.patientEnrollment?.preferredLaboratory?.value === "" && (
          <Message color="warning">
            You have no diagnostic test package.
          </Message>
        )}
        {state?.patientEnrollment?.preferredLaboratory?.value !== "" && (
          <Fragment>
            <p>Tests included:</p>
            <Message className="mt-1 mb-1">
              <div className="content">
                <ul className="mt-0">
                  {packagesIncluded?.tests?.map(test => (
                    <li className="pb-0">{test}</li>
                  ))}
                </ul>
              </div>
            </Message>
            <div className="columns is-vcentered is-mobile ml-1 ml-0-mobile">
              <div className="column">
                <div
                  className={classNames(
                    "is-flex pr-1 is-align-items-center is-flex-wrap mt-1-mobile",
                    styles["styledRadio__label"]
                  )}
                >
                  {laboratory?.logo && (
                    <div className="logo-container mr-3 mr-0-mobile">
                      <Img
                        fixed={data[laboratory.logo].childImageSharp.fixed}
                      />
                    </div>
                  )}
                  <div className="column pl-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: laboratory?.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <PackageFormAddon
              selectedPackage={
                PATIENT_ENROLLMENT_STATE?.preferredLaboratory?.value
              }
              values={PATIENT_ENROLLMENT_STATE}
            />
          </Fragment>
        )}
      </Section>
      <Message color="warning" className="mt-4 mb-2">
        <span className="has-text-black">
          A MedGrocer Kumusta Dok coordinator will verify when your enrollment
          is approved.
        </span>
        <div className="content mb-0 mt-1 has-text-black">
          <p className="is-size-6">
            You will need to present your LOA, government-issued ID, and
            laboratory request (printed or ePrescription) when claiming your
            diagnostic support from our partner laboratories. Accepted IDs
            include:
          </p>
          <ul className="mt-0">
            <li className="is-size-6 pl-1 pb-0">Passport</li>
            <li className="is-size-6 pl-1 pb-0">Driver’s License</li>
            <li className="is-size-6 pl-1 pb-0">SSS ID</li>
            <li className="is-size-6 pl-1 pb-0">GSIS ID</li>
            <li className="is-size-6 pl-1 pb-0">UMID</li>
            <li className="is-size-6 pl-1 pb-0">Postal ID</li>
            <li className="is-size-6 pl-1 pb-0">Voter’s ID</li>
            <li className="is-size-6 pl-1 pb-0">Senior Citizen ID</li>
          </ul>
        </div>
      </Message>
    </div>
  )
}

export default PatientEnrollmentSummaryAddon
