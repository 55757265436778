import React from "react"

import Message from "elements/Message"

const FinancialAssessmentAddon = () => {
  return (
    <div className="mb-3">
      <Message color="warning" className="mt-4">
        <span className="has-text-black">
          We will reach out to your medical secretary to assist your patients in
          scheduling their consultations.
        </span>
      </Message>
    </div>
  )
}

export default FinancialAssessmentAddon
