import React, { useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "../Layout/Layout"
import Message from "elements/Message"
import Section from "elements/Section"
import Container from "layout/Container"
import EnrollmentAddons from "./EnrollmentAddons"
import ActionButtons from "elements/ActionButtons"

import { AppContext } from "../../context/AppContext"
import { isFutureDate } from "../../services/general"
import { generateFormField } from "elements/Form/services/form"
import { disableButton, getInitialFormValues } from "./services/form"
import { getValidationSchema } from "./utils/enrollmentFormValidationSchema"

import {
  GASTBY_DOCTOR_ENCRYPTED_KEY,
  GATSBY_DOCTOR_ENCRYPTED_VALUE,
} from "gatsby-env-variables"

const EnrollmentMechanics = props => {
  const { state, dispatch } = useContext(AppContext)

  const {
    module,
    nextPath,
    backPath,
    enrollmentForm,
    enrollmentIntialState,
  } = props.pageContext
  const nextLink = `/${module.name}/${nextPath}`

  let formInitialState = getInitialFormValues({
    state,
    module: module.name,
    enrollmentIntialState,
  })

  useEffect(() => {
    if (
      sessionStorage.getItem(GASTBY_DOCTOR_ENCRYPTED_KEY) !==
      GATSBY_DOCTOR_ENCRYPTED_VALUE
    ) {
      navigate(backPath)
    }
  }, [backPath])

  const handleFormSubmit = (values, { setErrors }) => {
    let formErrors = {}

    if (module.name === "doctor") {
      dispatch({
        type: "SAVE_DOCTOR_ENROLLMENT",
        payload: values,
      })
      navigate(nextLink)
    } else {
      let { followUpConsultation } = values

      if (!isFutureDate(followUpConsultation)) {
        formErrors.followUpConsultation = {
          month: { value: "Please enter a valid follow-up consultation date." },
          date: { value: "Please enter a valid follow-up consultation date." },
        }
      }

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors)
      } else {
        dispatch({
          type: "SAVE_PATIENT_ENROLLMENT",
          payload: {
            ...values,
          },
        })
        navigate(nextLink)
      }
    }
  }

  return (
    <Layout
      title={module?.title}
      subtitle={module?.subtitle}
      seoTitle={`${module.seoTitle}`}
    >
      <Container isCentered>
        <Formik
          initialValues={formInitialState}
          validationSchema={getValidationSchema(module.name)}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {enrollmentForm.map(section => (
                <Section
                  title={section?.section}
                  id={section?.id}
                  subtitle={section?.subtitle}
                  isRequired
                >
                  {section?.fields.map(field => {
                    if (!field?.referenceAnswer) {
                      return generateFormField({
                        formFields: section?.fields,
                        formField: field,
                        values,
                        setFieldValue,
                      })
                    }
                    return null
                  })}
                </Section>
              ))}
              <EnrollmentAddons values={values} module={module.addon} />
              <EnrollmentAddons module={module.name} />
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ActionButtons
                back={{ label: "Back", link: backPath }}
                submit={{
                  label: "Next",
                  disabled: disableButton(module.name, state),
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default EnrollmentMechanics
