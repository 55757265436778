import * as Yup from "yup"
import {
  YEAR_NOW,
  INVALID_YEAR,
  INVALID_EMAIL,
  REQUIRED_MESSAGE,
  INVALID_CONTACT_INFO,
  INVALID_MOBILE_NUMBER,
} from "services/validations"

const patientEnrollmentSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  // emailAddress: Yup.string()
  //   .email(INVALID_EMAIL)
  //   .required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL),
  prescribingDoctor: Yup.string().required(REQUIRED_MESSAGE),
  firstTimeConsulting: Yup.string().required(REQUIRED_MESSAGE),
  prescribedTests: Yup.string().required(REQUIRED_MESSAGE),
  clinicHospital: Yup.string().required(REQUIRED_MESSAGE),
  followUpConsultation: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(YEAR_NOW, INVALID_YEAR)
      .max(YEAR_NOW + 2, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
})

const doctorEnrollmentSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MESSAGE),
  prcNumber: Yup.string().required(REQUIRED_MESSAGE),
  specialization: Yup.object().shape({
    value: Yup.string()
      .required("This field is required")
      .nullable(),
  }),
  otherSpecialization: Yup.string().when("specialization", {
    is: specialization => specialization?.value === "Other (please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hospitalClinic: Yup.string().required(REQUIRED_MESSAGE),
  department: Yup.object().shape({
    value: Yup.string()
      .required("This field is required")
      .nullable(),
  }),
  otherDepartment: Yup.string().when("department", {
    is: hospitalDepartment =>
      hospitalDepartment?.value === "Other (please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  isAffiliatedWithAGovernment: Yup.string().required(REQUIRED_MESSAGE),
  clinicSchedule: Yup.string().required(REQUIRED_MESSAGE),
  doctorMobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  doctorEmail: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  secretaryName: Yup.string().required(REQUIRED_MESSAGE),
  secretaryrMobileNumber: Yup.string()
    // .min(11, INVALID_CONTACT_INFO)
    .max(11, INVALID_CONTACT_INFO)
    // .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  // secretaryrEmail: Yup.string()
  //   .email(INVALID_EMAIL)
  //   .required(REQUIRED_MESSAGE),
})

export const getValidationSchema = module => {
  if (module === "patient") return patientEnrollmentSchema
  return doctorEnrollmentSchema
}
