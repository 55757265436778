import React from "react"

import DoctorEnrollmentAddon from "../Addons/DoctorEnrollmentAddons"
import PatientEnrollmentAddon from "../Addons/PatientEnrollmentAddons"
import CommunicationPreferenceAddon from "../Addons/CommunicationPreferenceAddon"
import PatientEnrollmentSummaryAddon from "../Addons/PatientEnrollmentSummaryAddon"

const EnrollmentAddons = ({ module, values }) => {
  switch (module) {
    case "doctor":
      return <DoctorEnrollmentAddon />
    // case "patient":
    //   return <PatientEnrollmentAddon />
    case "patient-summary":
      return <PatientEnrollmentSummaryAddon />
    case "def-communication-preferences":
      return <CommunicationPreferenceAddon values={values} />
    default:
      return null
  }
}

export default EnrollmentAddons
