import React from "react"

import Section from "elements/Section"
import FormInput from "elements/Form/FormInput"

const CommunicationPreferences = () => {
  return (
    <Section
      id="communication-preference"
      title="Communication Preferences"
      subtitle="We will reach out to you through the contact details below."
      isRequired
    >
      <p className="title is-4 mb-1 mt-2">Doctor</p>
      <FormInput
        type="email"
        name="doctorEmail"
        label="Email"
        placeholder="mdelacruz@gmail.com"
        isRequired
      />
      <FormInput
        type="tel"
        name="doctorMobileNumber"
        label="Mobile Number"
        placeholder="09123456789"
        maxLength={11}
        isRequired
      />
      <p className="title is-4 mb-1 mt-2">Medical Secretary</p>
      <FormInput
        name="secretaryName"
        label="Name"
        placeholder="Maria Dela Cruz"
        isRequired
      />
      <FormInput
        type="email"
        name="secretaryrEmail"
        label="Email"
        placeholder="mdelacruz@gmail.com"
      />
      <FormInput
        type="number"
        name="secretaryrMobileNumber"
        helper={
          <span className="is-italic">Input a mobile or landline number</span>
        }
        label="Contact Number"
        maxLength={11}
        placeholder="09123456789 / 88881234"
        isRequired
      />
    </Section>
  )
}

export default CommunicationPreferences
